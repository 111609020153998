

  #slideset2{
    position: relative;
    height: 150px;
    overflow: hidden;
    font-size: 65px;
   
}

    

    #slideset6{
        position: relative;
        height: 110px;
        overflow: hidden;
        font-size: 65px;
       
    }
    
    
     
    #slideset6 > span {
        animation: 12s autoplay6 infinite linear;
        position: absolute; 
        font-weight: bold;
        top: 0; left: 0; 
        opacity: 0.0;
        background: var(--tj-theme-primary);
    background: -webkit-gradient(linear, left top, right top, from(var(--tj-theme-primary)), to(var(--tj-white)));
    background: -o-linear-gradient(left, var(--tj-theme-primary) 0%, var(--tj-white) 100%);
    background: linear-gradient(to right, var(--tj-theme-primary) 0%, var(--tj-white) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    
        @keyframes autoplay6 {
            0% {opacity: 0.0}
            4% {opacity: 1.0}
            33.33% {opacity: 1.0}
            37.33% {opacity: 0.0}
            100% {opacity: 0.0}
          }
          #slideset6 > span:nth-child(1) {animation-delay: 0s}
          #slideset6 > span:nth-child(2) {animation-delay: 4s}
          #slideset6 > span:nth-child(3) {animation-delay: 8s}




          @-webkit-keyframes scroll {
            0% {
              -webkit-transform: translateX(0);
              transform: translateX(0);
            }
            100% {
              -webkit-transform: translateX(calc(-250px * 7));
              transform: translateX(calc(-250px * 7));
            }
          }
          @keyframes scroll {
            0% {
              -webkit-transform: translateX(0);
              transform: translateX(0);
            }
            100% {
              -webkit-transform: translateX(calc(-250px * 7));
              transform: translateX(calc(-250px * 7));
            }
          }
          .slider {
           
            height: 100px;
            margin: auto;
            overflow: hidden;
            position: relative;
            width: auto;
          }
          .slider::before,
          .slider::after {
            
            content: "";
            height: 100px;
            position: absolute;
            width: 200px;
            z-index: 2;
          }
          .slider::after {
            right: 0;
            top: 0;
            -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
          }
          .slider::before {
            left: 0;
            top: 0;
          }
          .slider .slide-track {
            -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
            display: flex;
            width: calc(250px * 17);
          }
          .slider .slide {
            height: 100px;
            width: 250px;
            margin: 0 15px;
          }
          .slider .slide img{
              max-width: 100%;
              width: 210px;
              height: auto;
          }